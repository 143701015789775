
import { defineComponent, reactive, toRefs, onMounted, ref } from "vue";
import { TUIEnv } from "./TUIKit/TUIPlugin";
import { handleErrorPrompts } from "./TUIKit/TUIComponents/container/utils";
import TencentCloudChat from "@tencentcloud/chat";
import TUIProfile from "@/TUIKit/TUIComponents/container/TUIProfile/index.vue";
import axios from "axios/index";

export default defineComponent({
  name: "App",
  setup() {
    const searchRef = ref<any>();

    // var url: any = new URL(window.location.href);
    // // 解析response
    // var response: any = url.searchParams.get('userId');
    // console.log("111111111111" + response)

    //根据参数名获取对应的url参数
    const getQueryString = (name: any) => {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    };
    let tuiChat: any = "";

    onMounted(() => {
      tuiChat = process.env.VUE_APP_TUI_CHAT;
      const userId: any = getQueryString("userId");
      // console.log(userId)
      // 和谁聊
      sessionStorage.setItem("user", userId);

      // 当前登录用户id
      const token: any = getQueryString("token");
      if (token) {
        axios
          .get(process.env.VUE_APP_BASE_URL + "home/im/getInfo", {
            headers: {
              Authorization: token,
            },
          })
          .then((res) => {
            // console.log(res.data.data.id)
            if (res.data.code === 200) {
              sessionStorage.setItem("currentId", res.data.data.id);
            } else {
              alert("请通过页面访问");
            }
          });
      } else {
        alert("请通过页面访问");
      }

      // userSig
      axios
        .get(process.env.VUE_APP_BASE_URL + "home/im/userSig", {
          headers: {
            Authorization: getQueryString("token"),
          },
        })
        .then((res) => {
          // console.log(res.data.data)

          sessionStorage.setItem("userSig", res.data.data);
        });
      setTimeout(() => {
        b();
      }, 2000);

      setTimeout(() => {
        if (window.location.href.indexOf("#reloaded") == -1) {
          window.location.href = window.location.href + "#reloaded";
          window.location.reload();
        }
      }, 500);
    });

    const data: any = reactive({
      env: TUIEnv(),
      currentModel: "conversation",
      showCall: false,
      showCallMini: false,
      selectedList: [],
      a: {
        userIDList: [],
      },
    });
    const TUIServer = (window as any)?.TUIKitTUICore?.TUIServer;
    const handleCurrentConversation = (value: string) => {
      data.currentModel = value ? "message" : "conversation";
    };
    // beforeCalling：在拨打电话前与收到通话邀请前执行
    const beforeCalling = (type: string, error: any) => {
      if (error) {
        handleErrorPrompts(error, type);
        return;
      }
      data.showCall = true;
    };
    // afterCalling：结束通话后执行
    const afterCalling = () => {
      data.showCall = false;
      data.showCallMini = false;
    };
    // onMinimized：组件切换最小化状态时执行
    const onMinimized = (
      oldMinimizedStatus: boolean,
      newMinimizedStatus: boolean
    ) => {
      data.showCall = !newMinimizedStatus;
      data.showCallMini = newMinimizedStatus;
    };
    // onMessageSentByMe：在整个通话过程内发送消息时执行
    const onMessageSentByMe = async (message: any) => {
      TUIServer?.TUIChat?.handleMessageSentByMeToView(message);
      return;
    };

    //聊天框
    const b = () => {
      let options = {
        SDKAppID: Number(process.env.VUE_APP_SDKAppID), // 接入时需要将0替换为您的即时通信应用的 SDKAppID
      };
      let chat = TencentCloudChat.create(options);
      const userID: Array<string> = [];
      const user = sessionStorage.getItem("user");
      userID[0] = user ? user : "0";
      // const promise = chat.getUserProfile({userIDList: ['rhghgfbdfg'] });
      data.a.userIDList = userID;
      const promise = chat.getUserProfile(data.a);
      promise
        .then(function (imResponse) {
          data.selectedList = imResponse.data; // 存储用户资料的数组 - [Profile]
          // ctx.emit('submit', data.selectedList);
          // console.log(data.selectedList)
          searchRef.value.submit(data.selectedList);
        })
        .catch(function (imError) {
          console.warn("getUserProfile error:", imError); // 获取其他用户资料失败的相关信息
        });
    };
    return {
      ...toRefs(data),
      handleCurrentConversation,
      beforeCalling,
      afterCalling,
      onMinimized,
      onMessageSentByMe,
      searchRef,
    };
  },
});
