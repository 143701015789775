import {createApp} from 'vue';
import App from './App.vue';
import {TUIComponents, TUICore, genTestUserSig} from './TUIKit';

// import TUICallKit
import {TUICallKit} from '@tencentcloud/call-uikit-vue';


// import TUINotification
import TUINotification from './TUIKit/TUIPlugin/TUINotification';

const SDKAppID = Number(process.env.VUE_APP_SDKAppID); // Your SDKAppID
const userSig: any = sessionStorage.getItem('userSig')

// 当前登录id
const userID: any = sessionStorage.getItem('currentId'); // User ID

// console.log(userSig, userID)

// init TUIKit
const TUIKit = TUICore.init({
    SDKAppID,
});

// TUIKit add TUIComponents
TUIKit.use(TUIComponents);

// TUIKit add TUICallKit
TUIKit.use(TUICallKit);

// init TUINotification
TUIKit.use(TUINotification, {
    notificationTitle: process.env.VUE_APP_NOTIFICATION_TITLE,
    notificationIcon: process.env.VUE_APP_NOTIFICATION_ICON,
});

// login TUIKit
TUIKit.login({
    userID: userID,
    userSig: userSig,
});

createApp(App).use(TUIKit).mount('#app');

