import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TUIProfile = _resolveComponent("TUIProfile")!
  const _component_Transfer = _resolveComponent("Transfer")!
  const _component_CreateGroup = _resolveComponent("CreateGroup")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["TUI-search", [_ctx.env.isH5 ? 'TUI-search-H5' : '']]),
    ref: "dialog"
  }, [
    _createVNode(_component_TUIProfile),
    _createVNode(_component_Dialog, {
      show: _ctx.open,
      isH5: _ctx.env.isH5,
      isHeaderShow: false,
      isFooterShow: false,
      background: false,
      "onUpdate:show": _cache[0] || (_cache[0] = (e) => (_ctx.open = e))
    }, {
      default: _withCtx(() => [
        (_ctx.step === 1)
          ? (_openBlock(), _createBlock(_component_Transfer, {
              key: 0,
              isSearch: _ctx.needSearch,
              title: _ctx.showTitle,
              list: _ctx.searchUserList,
              isH5: _ctx.env.isH5,
              isRadio: _ctx.createConversationType === 'isC2C',
              onSearch: _ctx.handleSearch,
              onSubmit: _ctx.submit,
              onCancel: _ctx.toggleOpen
            }, null, 8, ["isSearch", "title", "list", "isH5", "isRadio", "onSearch", "onSubmit", "onCancel"]))
          : (_openBlock(), _createBlock(_component_CreateGroup, {
              key: 1,
              onSubmit: _ctx.create,
              onCancel: _ctx.toggleOpen,
              isH5: _ctx.env.isH5
            }, null, 8, ["onSubmit", "onCancel", "isH5"]))
      ]),
      _: 1
    }, 8, ["show", "isH5"])
  ], 2))
}