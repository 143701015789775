import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7567108e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "transfer-h5-header"
}
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "main" }
const _hoisted_4 = { class: "left" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "list" }
const _hoisted_8 = { class: "all" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "name" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "right" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "list" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = ["src"]
const _hoisted_18 = { key: 0 }
const _hoisted_19 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["transfer", [_ctx.isH5 ? 'transfer-h5' : '']])
  }, [
    (_ctx.isH5)
      ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
          _createElementVNode("i", {
            class: "icon icon-back",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
          }),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("main", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.isSearch)
          ? (_openBlock(), _createElementBlock("header", _hoisted_5, [
              _createElementVNode("input", {
                type: "text",
                onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)), ["enter"])),
                placeholder: _ctx.$t('component.请输入userID'),
                enterkeyhint: "search"
              }, null, 40, _hoisted_6)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("main", null, [
          _createElementVNode("ul", _hoisted_7, [
            (_ctx.optional.length > 1 && !_ctx.isRadio)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  class: "list-item",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.selectedAll && _ctx.selectedAll(...args)))
                }, [
                  _createElementVNode("i", {
                    class: _normalizeClass(["icon", [
                  _ctx.selectedList.length === _ctx.optional.length
                    ? 'icon-selected'
                    : 'icon-unselected',
                ]])
                  }, null, 2),
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("component.全选")), 1)
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "list-item",
                key: index,
                onClick: ($event: any) => (_ctx.selected(item))
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(["icon", [
                  item?.isDisabled && 'disabled',
                  _ctx.selectedList.indexOf(item) > -1
                    ? 'icon-selected'
                    : 'icon-unselected',
                ]])
                }, null, 2),
                (!_ctx.isCustomItem)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("img", {
                        class: "avatar",
                        src: 
                    item?.avatar ||
                    'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'
                  ,
                        onerror: "this.src='https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'"
                      }, null, 8, _hoisted_10),
                      _createElementVNode("span", _hoisted_11, _toDisplayString(item?.nick || item?.userID), 1),
                      (item?.isDisabled)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, "（" + _toDisplayString(_ctx.$t("component.已在群聊中")) + "）", 1))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _renderSlot(_ctx.$slots, "left", {
                      key: 1,
                      data: item
                    }, undefined, true)
              ], 8, _hoisted_9))
            }), 128))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        (!_ctx.isH5)
          ? (_openBlock(), _createElementBlock("header", _hoisted_14, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("ul", _hoisted_15, [
          (_ctx.selectedList.length > 0 && !_ctx.isH5)
            ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(_ctx.$t("component.已选中")) + _toDisplayString(_ctx.selectedList.length) + _toDisplayString(_ctx.$t("component.人")), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedList, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "list-item space-between",
              key: index
            }, [
              _createElementVNode("aside", null, [
                (!_ctx.isCustomItem)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("img", {
                        class: "avatar",
                        src: 
                    item?.avatar ||
                    'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'
                  ,
                        onerror: "this.src='https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'"
                      }, null, 8, _hoisted_17),
                      (!_ctx.isH5)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(item.nick || item.userID), 1))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _renderSlot(_ctx.$slots, "right", {
                      key: 1,
                      data: item
                    }, undefined, true)
              ]),
              (!_ctx.isH5)
                ? (_openBlock(), _createElementBlock("i", {
                    key: 0,
                    class: "icon icon-cancel",
                    onClick: ($event: any) => (_ctx.selected(item))
                  }, null, 8, _hoisted_19))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ], 512), [
          [_vShow, _ctx.resultShow]
        ]),
        _createElementVNode("footer", null, [
          _createElementVNode("button", {
            class: "btn btn-cancel",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
          }, _toDisplayString(_ctx.$t("component.取消")), 1),
          (_ctx.selectedList.length > 0)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
              }, _toDisplayString(_ctx.$t("component.完成")), 1))
            : (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "btn btn-no",
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
              }, _toDisplayString(_ctx.$t("component.完成")), 1))
        ])
      ])
    ])
  ], 2))
}