
import { defineComponent, reactive, toRefs, ref, watch } from "vue";
import { useStore } from "vuex";
import TUIProfileEdit from "./components/TUIProfileEdit";
import TencentCloudChat from "@tencentcloud/chat";

const TUIProfile = defineComponent({
  name: "TUIProfile",
  components: {
    TUIProfileEdit,
  },
  setup(props: any, ctx: any) {
    const TUIServer: any = TUIProfile?.TUIServer;

    // 当前用户登录id
    const currentId: any = sessionStorage.getItem("currentId");
    console.log(currentId);

    const selectVal = ref("");
    const a = ref("");

    // watch(selectVal, (newValue, oldValue) => {
    //   console.log(newValue)
    //   let options = {
    //     SDKAppID: process.env.SDKAppID, // 接入时需要将0替换为您的即时通信应用的 SDKAppID
    //   };
    //   let chat = TencentCloudChat.create(options);
    //   let promise = chat.setSelfStatus({customStatus: selectVal.value})
    // TODO: 设置自定义状态
    // promise.then(function (imResponse) {
    //   console.log("------"+imResponse.data);
    //   const {userID, statusType, customStatus} = imResponse.data;
    // })
    //
    // let promises = chat.getUserStatus({userIDList: ['customer_service']});
    // promises.then(function (imResponse) {
    //     console.log("--------------------",imResponse.data)
    //   const {successUserList} = imResponse.data;
    //   successUserList.forEach((item:any) => {
    //     const {userID, statusType, customStatus} = item;
    //     a.value = item.customStatus
    //   });
    // }).catch(function(imError) {
    //   console.warn('setSelfStatus error:', imError); // 设置用户自己的自定义状态失败的相关信息
    // });

    // })

    const data = reactive({
      profile: {},
      isEdit: false,
      genderLabel: {
        [TUIServer.TUICore.TIM.TYPES.GENDER_MALE]: "男",
        [TUIServer.TUICore.TIM.TYPES.GENDER_FEMALE]: "女",
        [TUIServer.TUICore.TIM.TYPES.GENDER_UNKNOWN]: "不显示",
      },
      env: TUIServer.TUICore.TUIEnv,
    });

    TUIProfileEdit.TUIServer = TUIServer;

    TUIServer.bind(data);

    const VuexStore =
      ((window as any)?.TUIKitTUICore?.isOfficial && useStore && useStore()) ||
      {};

    const submit = async (profile: any) => {
      const options: any = {
        nick: profile.nick,
        avatar: profile.avatar,
        gender: profile.gender || TUIServer.TUICore.TIM.TYPES.GENDER_UNKNOWN,
        selfSignature: profile.selfSignature,
        birthday: profile.birthday,
      };
      if (
        TUIServer.TUICore.getStore().TUIProfile.profile.nick !== profile.nick
      ) {
        (window as any)?.TUIKitTUICore?.isOfficial &&
          VuexStore?.commit &&
          VuexStore?.commit("handleTask", 2);
      }
      try {
        await TUIServer.updateMyProfile(options);
      } catch (error) {
        console.log(error);
      }
      if (!data.env.isH5) {
        cancel();
      }
    };

    const cancel = () => {
      if (data.env.isH5) {
        data.isEdit = false;
      } else {
        changeStatus();
      }
    };

    const changeStatus = () => {
      ctx.emit("changeStatus");
    };

    const toggleEdit = () => {
      if (data.env.isH5) {
        data.isEdit = true;
      }
    };
    return {
      currentId,
      selectVal,
      ...toRefs(data),
      submit,
      cancel,
      changeStatus,
      toggleEdit,
      a,
    };
  },
});
export default TUIProfile;
