
import { defineComponent, reactive, watchEffect, toRefs, computed } from "vue";
import TencentCloudChat from "@tencentcloud/chat";

export default defineComponent({
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    selectedList: {
      type: Array,
      default: () => [],
    },
    isSearch: {
      type: Boolean,
      default: () => true,
    },
    isRadio: {
      type: Boolean,
      default: () => false,
    },
    isCustomItem: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => "",
    },
    type: {
      type: String,
      default: () => "",
    },
    isH5: {
      type: Boolean,
      default: () => false,
    },
    resultShow: {
      type: Boolean,
      default: () => true,
    },
  },
  setup(props: any, ctx: any) {
    const data: any = reactive({
      type: "",
      list: [],
      selectedList: [],
      isSearch: true,
      isCustomItem: false,
      title: "",
      a: {
        userIDList: [],
      },
    });

    watchEffect(() => {
      if (props.isCustomItem) {
        for (let index = 0; index < props.list.length; index++) {
          if (props.list[index].conversationID.indexOf("@TIM#SYSTEM") > -1) {
            // eslint-disable-next-line vue/no-mutating-props
            props.list.splice(index, 1);
          }
          data.list = props.list;
        }
      } else {
        data.list = props.list;
      }
      data.selectedList = props.selectedList;
      data.isSearch = props.isSearch;
      data.isCustomItem = props.isCustomItem;
      data.title = props.title;
      data.type = props.type;
    });

    // 可选项
    const optional = computed(() =>
      data.list.filter((item: any) => !item.isDisabled)
    );

    const handleInput = (e: any) => {
      ctx.emit("search", e.target.value);
    };

    const selected = (item: any) => {
      if (item.isDisabled) {
        return;
      }
      let list: any = data.selectedList;
      const index: number = list.indexOf(item);
      if (index > -1) {
        return data.selectedList.splice(index, 1);
      }
      if (props.isRadio) {
        list = [];
      }
      list.push(item);
      data.selectedList = list;
    };

    const selectedAll = () => {
      if (data.selectedList.length === optional.value.length) {
        data.selectedList = [];
      } else {
        data.selectedList = [...optional.value];
      }
    };

    const submit = () => {
      let options = {
        SDKAppID: Number(process.env.VUE_APP_SDKAppID), // 接入时需要将0替换为您的即时通信应用的 SDKAppID
      };
      let chat = TencentCloudChat.create(options);
      const userID: Array<string> = [];
      const user = sessionStorage.getItem("user");
      userID[0] = user ? user : "0";
      // const promise = chat.getUserProfile({userIDList: ['rhghgfbdfg'] });
      data.a.userIDList = userID;
      const promise = chat.getUserProfile(data.a);
      promise
        .then(function (imResponse) {
          data.selectedList = imResponse.data; // 存储用户资料的数组 - [Profile]
          ctx.emit("submit", data.selectedList);
        })
        .catch(function (imError) {
          console.warn("getUserProfile error:", imError); // 获取其他用户资料失败的相关信息
        });
      // ctx.emit('submit', data.selectedList);
    };

    const cancel = () => {
      ctx.emit("cancel");
    };

    return {
      ...toRefs(data),
      optional,
      handleInput,
      selected,
      selectedAll,
      submit,
      cancel,
    };
  },
});
